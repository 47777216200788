import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/rem.ts';
// Vant
import {
  Button,
  Overlay,
  Cell,
  Icon,
  Collapse,
  CollapseItem,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  ImagePreview,
} from 'vant';
// ElementPlus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import util from './utils/util';
util.bodyScale();
window.onresize = () => {
  util.bodyScale();
};

createApp(App)
  .use(ElementPlus, { size: 'small', zIndex: 3000 })
  .use(store as any)
  .use(router as any)
  .use(Button as any)
  .use(Overlay as any)
  .use(Cell as any)
  .use(Icon as any)
  .use(Collapse as any)
  .use(CollapseItem as any)
  .use(Swipe as any)
  .use(SwipeItem as any)
  .use(Tab as any)
  .use(Tabs as any)
  .use(ImagePreview as any)
  .mount('#app');
