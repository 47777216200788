/**
 * 功能说明：路由：首页
 */
import { RouteRecordRaw } from 'vue-router'
const Layout = () => import('@/views/layout/Layout.vue'); // 首页
const help = () => import('@/views/home/pc/help.vue'); // 首页
const homeRouter: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Layout,
        // name: 'home',
        redirect: '/',
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/home/pc/home.vue'),
                meta: {
                    requiresAuth: false,
                },
            },
            // {
            //     path: 'download',
            //     name: 'download',
            //     redirect:'download',
            //     component: () => import('@/views/home/pc/download/index.vue'),
            //     meta: {
            //         requiresAuth: false,
            //     },
            //     children:[
            //         {
            //             path: '',
            //             component: () => import('@/views/home/pc/download/download.vue'),
            //             meta: {
            //                 requiresAuth: false,
            //             },
            //         },
            //         {
            //             path: 'authority',
            //             name:'authority',
            //             component: () => import('@/views/home/pc/download/authority.vue'),
            //             meta: {
            //                 requiresAuth: false,
            //             },
            //         }
            //     ]
            // },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/views/home/pc/about.vue'),
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: 'help',
                name: 'help',
                component: help,
                meta: {
                    requiresAuth: false,
                },
                
            }
        ],
        
    }

];

export default homeRouter;
