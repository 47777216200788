/**
 * 功能说明：路由：首页
 */

import { RouteRecordRaw } from 'vue-router'

const h5Router: Array<RouteRecordRaw> = [
    
    {
        path:'/h5',
        component: () => import('@/views/home/h5/index.vue'),
        redirect: '/h5/home',
        meta: {
            requiresAuth: false,
        },
        children:[
            {
              path:'home',
              name:'h5home',
              meta: {
                requiresAuth: false
              },
              component: () => import('@/views/home/h5/home.vue'),
            },
            {
              path:'about',
              name:'h5about',
              meta: {
                requiresAuth: false
              },
              component: () => import('@/views/home/h5/about.vue'),
            },
            {
              path:'help',
              name:'h5help',
              meta: {
                requiresAuth: false
              },
              component: () => import('@/views/home/h5/help.vue'),
            },
            {
              path:'helpdetail/:id',
              name:'h5helpdetail',
              meta: {
                requiresAuth: false
              },
              component: () => import(`@/views/home/h5/components/helppages/helpPages.vue`),
            },
            // {
            //   path:'download',
            //   name:'h5download',
            //   meta: {
            //     requiresAuth: false
            //   },
            //   component: () => import('@/views/home/h5/download.vue'),
              
            // },
            // {
            //   path:'downloaddetail',
            //   name:'h5downloaddetail',
            //   meta: {
            //     requiresAuth: false
            //   },
            //   component: () => import('@/views/home/h5/downloaddetail.vue'),
            // },
        ]
    }

];

export default h5Router;
