export default {

  bodyScale() {
    let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    let isChrome = userAgent.indexOf("Chrome") > -1; //判断是否Firefox浏览器
      let devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
      let scale = devicewidth / 1920; // 分母——设计稿的尺寸
     //  
    //  if(isChrome){
    //   document.body.style.zoom = scale;//放大缩小相应倍数
    //   //  document.body.style['transform'] = `scale(${scale})`;//放大缩小相应倍数
    //   //  document.body.style['transform-origin'] = `top center`;//放大缩小相应倍数
    //   //  document.body.style['-moz-transform'] = `scale(${scale})`;//放大缩小相应倍数
    //   //  document.body.style['-moz-transform-origin'] = `top center`;//放大缩小相应倍数
    //  }
  }
}

let rules = {  // act一测---------build哪个环境静态文件，放开哪一个环境的rules
  targetUrl: 'http://stock-test1.jirongyunke.net/',
};
const host = window.location.host 
if(host === 'www.gayang.hk') {  // act二测
  rules = {
    targetUrl: 'http://download.gayang.hk/',
  }
}
export const configApi = rules