<template>
	<router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'App',
	components: {}
});
</script>

<style lang="less">
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	list-style: none;
	outline: none;
	word-break: break-all;
	font-size: 16px;
}
div {
	box-sizing: border-box;
}
body,
html {
	word-break: break-all;
	background: #f4f5f6;
	color: #333;
	font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
		Microsoft YaHei, Arial, sans-serif;
	//  transform-origin: 0 0 0;       /*指定缩放的基本点*/
	// -moz-transform-origin: 0 0 0;
	// -ms-transform-origin: 0 0 0;
	// -webkit-transform-origin: 0 0 0;
	// -o-transform-origin: 0 0 0;
}
</style>
