import { createRouter, createWebHistory } from 'vue-router'
import homeRouter from './home/index'; // 用户中心模块
import anomalyPageRouter from './anomaly-page/index'; // 异常页面
import h5Router from './h5/index'; // 用户中心模块


const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    ...homeRouter,
    ...h5Router,
    ...anomalyPageRouter
  ]
})

export default router
